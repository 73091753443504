import store from '@/store'
import Api from './apiBinance'

const wsApi = new Api()

const subscribeSymbol = function (symbol) {
  if (store.state.binanceSpot.tickers[symbol] == null) {
    wsApi.onTicker(symbol, ticker => {
      const tick = {
        price: ticker.c ? ticker.c.replace(/^0+(\d)|(\d)0+$/gm, '$1$2') : 0,
        vol: parseFloat(ticker.q).toFixed(2),
        percent: parseFloat(ticker.P).toFixed(2),
        chg: ticker.p,
        high: ticker.h,
        low: ticker.l,
        open: ticker.o,
        time: ticker.E,
        symbol,
      }
      store.dispatch('binanceSpot/updateTicket', tick)
    })
  }
}

const unSubscribeSymbol = function (symbol) {
  wsApi.closeSubscription('ticker', false, symbol)
}

const subscribeChart = function (symbol, interval) {
  wsApi.onKline(symbol, interval, () => {})
}

const unSubscribeChart = function (symbol, interval) {
  wsApi.closeSubscription('kline', false, symbol, interval)
}

export {
  subscribeSymbol, unSubscribeSymbol, subscribeChart, unSubscribeChart,
}
